import { captureException } from '@sentry/nextjs';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { NextApiRequest, NextApiResponse } from 'next/types';
import { formatSentryError, formatSentryMessage } from '../../../../../helpers/telemetryHelpers';
import { CmsIntegrationSettings, FirestoreDocumentIdType } from '../../../../../types/types';

export default async function handler(req: NextApiRequest, res: NextApiResponse) {
    // early return if wrong method
    if (req.method !== 'POST') {
        const log = `cannot ${req.method}`;
        const message = formatSentryMessage(log, 'postCmsIntegrationSettings API');
        captureException(new Error(message));
        return res.status(400).send(log);
    }

    // * typing makes it so that ID is optional s.t. it can be removed if accidentally passed
    let payload = req.body as Omit<CmsIntegrationSettings, 'id'> & Partial<CmsIntegrationSettings>;

    try {
        const cmsIntegrationSettingsId = await postCmsIntegrationSettingsServerSide(payload);
        return res.status(200).send(cmsIntegrationSettingsId);
    } catch (error: unknown | AxiosError) {
        const { message, cause } = formatSentryError('Error creating cms integration settings', 'postCmsIntegrationSettings API', error);
        captureException(new Error(message, cause));

        if (axios.isAxiosError(error)) {
            // axios error
            const code = error.status ? error.status : 500;
            return res.status(code).send(error.message);
        } else {
            // Just a stock error
            return res.status(500).send(error);
        }
    }
}

export const postCmsIntegrationSettingsServerSide = async (payload: Omit<CmsIntegrationSettings, 'id'> & Partial<CmsIntegrationSettings>) => {
    try {
        if (payload.id) {
            delete payload.id;
        }
        if (payload.fetchedNarrationSettings) {
            delete payload.fetchedNarrationSettings;
        }

        let url = `${process.env.B2B_OPERATIONS_REST_API}/api/v1/cms-integration-settings/`;

        const response: AxiosResponse = await axios.post(url, payload);

        const cmsIntegrationSettingsId = response.data.id as FirestoreDocumentIdType;

        return cmsIntegrationSettingsId;
    } catch (error) {
        throw error;
    }
};
