import { User, UserCredential } from 'firebase/auth';
import { firestore } from 'firebase-admin';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { PLAN_VALUE, Frequencies, PAYWALLED_FEATURES } from '../utils/constants/featureFlagConstants';
import {
    AGE_ENUM,
    LISTENING_ACTIVITY_ENUM,
    LISTENING_TIME_ENUM,
    PERSONAL_GOAL_ENUM,
    PRIMARY_INTEREST_ENUM
} from '../utils/constants/userPropertyConstants';
import {
    APPLE_PODCASTS_NAME,
    GOOGLE_PODCASTS_NAME,
    PODCAST_SUBCATEGORIES,
    RSS_PODCASTS_NAME,
    SPOTIFY_PODCASTS_NAME
} from '../utils/constants/podcastConstants';
import { EVENT_NAME } from '../utils/constants/postHogConstants';
import { UseStepsReturn } from '@chakra-ui/react';
import { MULTIPLE_LANGUAGES_OPTION_NAME } from '../utils/constants/constants';
import { SUMMARIZE_LENGTH_DROP_DOWN_TYPE } from '../utils/constants/summarizeConstants';

export type FirebaseTokenType = string;
export type UserNameType = string;
export type OrgNameType = string;
export type EmailType = string;
export type JoinCodeType = string;
export type EncryptedOrgIdType = string;
export type EncryptedUserIdType = string;
export type UserIdType = string;
export type ViewIdType = string;
export type RoleType = string;
export type UIdType = string;
export type RouteIdentifierType = string;
export type RssCounterType = number;
export type TierType = string;
export type RssActiveType = boolean;
export type PodcastActiveType = boolean;
export type InstantCounterType = number;
export type CustomLimitType = number;
export type CustomCounterType = number;
export type LegacyCustomActiveType = boolean;
export type RssLimitType = string;
export type InstantLimitType = number;
export type MusicType = string;
export type NarrationMethodType = string;
export type NewNarrationType = string;
export type ApiKeyType = string;
export type AuthDomainType = string;
export type DatabaseURLType = string;
export type ProjectIdType = string;
export type StorageBucketType = string;
export type MessagingSenderIdType = string;
export type AppIdType = string;
export type MeasurementIdType = string;
export type UrlType = string;
export type NarrationIdType = number;
export type NarrationTitleType = string;
export type NarrationSiteNameType = string;
export type NarrationImageType = string;
export type NarrationDateCreatedType = string;
export type NarrationMusicType = string;
export type DeletionResponse = any;
export type ArticleTitleType = string;
export type SignupProvenanceType = string;
export type ListenedType = boolean | null;
export type PodcastPrivacyType = 'private' | 'public';
export type PodcastStatusType = 'off' | 'pending' | 'activated' | 'self-serve';
export type IsLoggedType = boolean | null;
export type ArticleDescriptionType = string;
export type PodcastActivationTimeType = string;
// Default firestore alphanumeric string
export type FirestoreTimestampType = firestore.Timestamp;
export type FirestoreDocumentIdType = string;
export type DocumentVersionType = number;
export type UTCEpochDateType = string;
export type LanguageCodeType = string;
export type VoiceCodeType = string;
export type PreNarratedNarrationType = 'preNarrated' | 'saveForLater';
export type OnboardingPreferenceSkippedType = 'SKIPPED';
export type OnboardingGoalOptionsType = PERSONAL_GOAL_ENUM | OnboardingPreferenceSkippedType | null;
export type OnboardingAgeOptionsType = AGE_ENUM | OnboardingPreferenceSkippedType | null;
export type OnboardingLanguageOptionsType = LanguageCodeType | OnboardingPreferenceSkippedType | null;
export type OnboardingListeningActivityOptionsType = LISTENING_ACTIVITY_ENUM | OnboardingPreferenceSkippedType | null;
export type OnboardingListeningTimeOptionsType = LISTENING_TIME_ENUM | OnboardingPreferenceSkippedType | null;
export type OnboardingPrimaryInterestOptionsType = PRIMARY_INTEREST_ENUM | OnboardingPreferenceSkippedType | null;
export type NarrationRequestCountMapType = Record<string, string>;
export type FeatureFlagContextStateType = boolean | string | undefined;
export type FeatureFlagContextSetStateType = Dispatch<SetStateAction<boolean | string | undefined>>;
export type PodcastNameType = typeof SPOTIFY_PODCASTS_NAME | typeof APPLE_PODCASTS_NAME | typeof GOOGLE_PODCASTS_NAME | typeof RSS_PODCASTS_NAME;
export type WordListType = Record<string, string>;
export type NarrationSettingsWordListType = WordListType | null;
export type CATEGORY_TYPE = keyof typeof PODCAST_SUBCATEGORIES;
export type AUDIO_PRELOAD_TYPE = 'auto' | 'metadata' | 'none';
export type ISO639LanguageCode = string;
export type LanguageDropedownValueType = ISO639 | typeof MULTIPLE_LANGUAGES_OPTION_NAME;
export type CloudBucketAudioUploadFolderNameType = 'intros' | 'outros' | 'background_tracks' | 'custom_blocks'; // * these are the folders in the GCP bucket
export type AuthPageType = 'signin' | 'signup';
export type NarrationSettingsNameType = string;

export interface UserInterface {
    id: EncryptedUserIdType;
    uid: UIdType;
    organisationId: EncryptedOrgIdType;
    username: UserNameType;
    email: EmailType;
    viewId: ViewIdType;
    filteredViewId: ViewIdType;
    routeIdentifier: RouteIdentifierType;
    role: RoleType;
    joinCode: JoinCodeType;
    organisation: OrgNameType;
    orgAdminId: UIdType;
    subscriptionData?: {
        rss_counter: RssCounterType;
        tier: TierType;
        rss_active: RssActiveType;
        podcast_active: PodcastActiveType;
        instant_counter: InstantCounterType;
        custom_limit: CustomLimitType;
        custom_counter: CustomCounterType;
        legacy_custom_active: LegacyCustomActiveType;
        rss_limit: RssLimitType;
        instant_limit: InstantLimitType;
    };
    token?: FirebaseTokenType;
}

export interface UrlNarrationInterface {
    url: UrlType;
    id: EncryptedUserIdType;
    user: User | Pick<User, 'uid' | 'email'>;
    narrationMethod: NarrationMethodType;
    music: NarrationMusicType;
    narrationType: NewNarrationType;
    titleVoice: NarrationTitleType;
    posthogProperties?: object;
    posthogSetOnceProperties?: object;
    posthogSetProperties?: object;
    wordList?: NarrationSettingsWordListType;
}

export interface TextNarrationInterface {
    bodyText: string;
    titleText?: string;
    bodyVoice: string;
    titleVoice: string;
    groupId: string | null;
    userId: string;
    narrationType: NarrationTypeEnum;
    narrationMethod: NarrationMethodEnum;
    businessDomain: string;
    version: string;
    intro?: string | null;
    outro?: string | null;
    backgroundTrack?: string | null;
    wordList?: NarrationSettingsWordListType;
}

export interface NarrationBlocksPayloadInterface {
    blocks: NarrationBlockInterface[];
    language?: string;
    narrationType: string;
    narrationMethod: string;
    groupId?: string | number;
    userId: string;
    businessDomain: string | null;
    version: string;
    wordList?: WordListType | null;
}

export type TextNarrationWithoutDefaultsType = Omit<TextNarrationInterface, 'narrationType' | 'narrationMethod' | 'businessDomain' | 'version'>;

export interface FirebaseConfig<T> {
    apiKey: ApiKeyType | T;
    authDomain: AuthDomainType | T;
    databaseURL: DatabaseURLType | T;
    projectId: ProjectIdType | T;
    storageBucket: StorageBucketType | T;
    messagingSenderId: MessagingSenderIdType | T;
    appId: AppIdType | T;
    measurementId: MeasurementIdType | T;
}

export interface PublishDateInterface {
    _seconds: number;
    _nanoseconds: number;
}

export enum StatusEnum {
    Success = 'SUCCESS',
    Failure = 'FAILURE',
    Pending = 'PENDING',
    Debug = 'DEBUG',
    Draft = 'DRAFT',
    Private = 'PRIVATE'
}

export enum NarrationMethodEnum {
    Rss = 'RSS',
    SingleTransformedTextRequest = 'SingleTransformedTextRequest',
    SingleNarrationRequest = 'SingleNarrationRequest',
    FileUpload = 'FileUpload',
    AudioShorts = 'AudioShorts',
    DailyRundown = 'DailyRundown',
    Preview = 'Preview',
    Renarrate = 'Renarrate',
    Hubspot = 'Hubspot',
    TwoSpeakerPodcast = 'TwoSpeakerPodcast',
    LeadGen = 'LeadGen'
}

export enum NarrationTypeEnum {
    Text = 'text',
    Blocks = 'blocks',
    Url = 'url',
    UploadedAudioFile = 'uploadedAudioFile',
    PartialUploadedAudioFile = 'partialUploadedAudioFile'
}

export enum ERROR_CODES {
    'EXTRACTION_FAILURE' = 100,
    'EXCEEDS_CHAR_LIMIT' = 200,
    'UNSUPPORTED_LANGUAGE' = 300,
    'AUDIO_PRODUCTION_ERROR' = 400
}

export interface AdditionalAudioChargesInterface {
    audioUrl: UrlType;
    duration: number;
    charCount: number;
    voiceCode: VoiceCodeType;
    narrationMethod: NarrationMethodEnum;
}

export interface NarrationInterface {
    id: FirestoreDocumentIdType;
    projectId: FirestoreDocumentIdType;
    creationDate: FirestoreTimestampType | PublishDateInterface;
    modifiedDate: FirestoreTimestampType;
    title?: string | null;
    description?: string | null;
    audio: UrlType | null;
    narrationSettings?: NarrationSettingsInterface | null;
    narrationMethod: NarrationMethodEnum;
    // URL is article URL, uploaded PDF, text bucket URL
    sourceUrl: UrlType;
    sourceType: NarrationTypeEnum;
    siteName: string | null;
    image: UrlType | null;
    sourceLanguage: LanguageCodeType | null;
    category?: string | null;
    publishDate: PublishDateInterface;
    version: DocumentVersionType;
    podcastChannelId?: FirestoreDocumentIdType;
    status?: StatusEnum;
    duration?: number;
    // * Leave as general object for now so that it can be expanded upon elsewhere without having to update here
    // https://www.notion.so/play-auris/ADR-Narration-Document-Update-for-Slack-8387892b61ee445689295f015efcbe46
    externalAppData?: object;
    errorCode?: ERROR_CODES;
    blocks?: NarrationBlockInterface[];
    originalText?: string;
    stats?: CharStatsInterface;
    additionalAudioCharges?: AdditionalAudioChargesInterface[];
    integrationIdentifier?: string;
    integrationInjectionIsCompleted?: boolean;
    retryCount?: number;
}

export interface NarrationPostPayloadInterface extends Omit<NarrationInterface, 'id' | 'creationDate' | 'modifiedDate' | 'publishDate'> {
    // * make publishDate a string so that it can be created by Date().toISOString()
    publishDate: PublishDateInterface | string;
    // * make status mandatory
    status: StatusEnum;
}

export interface ParsedCookieInterface {
    token: FirebaseTokenType;
    orgRouteIdentifier: RouteIdentifierType;
    userId: UserIdType;
}

export interface GetNarrationRequestInterface {
    orgRouteIdentifier: RouteIdentifierType;
    userId: UserIdType;
    limit: number;
    offset: number;
}

export interface GetNarrationCountInterface {
    orgRouteIdentifier: RouteIdentifierType;
    userId: UserIdType;
    listenedFilter: number | null;
}

export interface AudioPlayerProps {
    article_url: UrlType;
    org_name: OrgNameType;
    image_url?: UrlType;
    organisation_image?: UrlType;
    article_title: ArticleTitleType;
    url: UrlType;
}

export interface NewNarrationCreated {
    fileUrl: string;
    ttsId: number;
    widgetUrl: string;
    route_identifier: string;
    org_identifier: string;
}

export interface PageProps {
    setShowPlayer: Function;
}

export interface WordsmithResponseInterface {
    transformed_text: string;
}

export interface WordsmithParametersInterface {
    text?: string;
    system_prompt?: string;
    llm_model?: string;
    temperature?: number;
    examples?: Record<string, string>[];
    fomattedInputOutputExamples?: Record<string, string>[];
}

export enum NarrationBlockSourceTypeEnum {
    Text = 'text',
    Audio = 'audio'
}

export interface NarrationBlockInterface {
    intro: string | null; // audio url from a our offered selection
    outro: string | null; // audio url from a our offered selection
    backgroundTrack: string | null; // audio url from a our offered selection
    text: string;
    voice: string;
    audioUrl: string | null;
    sourceType?: NarrationBlockSourceTypeEnum;
    duration: number;
    postPauseLengthMilliseconds: number | null; // milliseconds to pause after the block is read
}

export interface AuthContextInterface {
    user: User | null;
    project: ProjectInterface | null;
    group: GroupInterface | null;
    subscription: SubscriptionInterface | null;
    loading: boolean;
    signOut: () => void;
    postLogin: (userCredential: UserCredential) => void;
    podcastChannel: PodcastChannelInterface | null | undefined;
    fetchAndUpdatePodcastChannel: () => void;
    fetchAndUpdateCmsIntegrationSettings: () => void;
    rssIntegrationSettings: RSSIntegrationSettings[] | undefined;
    hubspotIntegrationSettings: HubspotIntegrationSettings[] | undefined;
    unifiedIntegrationSettings: UnifiedIntegrationSettingsInterface[] | undefined;
    narrationSettings: NarrationSettingsInterface | null | undefined;
    allNarrationSettings: NarrationSettingsInterface[] | null | undefined;
    isNewUser: boolean;
    setIsNewUser: Dispatch<SetStateAction<boolean>>;
    fetchAndUpdateNarrationSettings: () => void;
    registerPosthogEventWithAuthProps: (eventName: EVENT_NAME, extraProperties?: Record<string, any>) => void;
    fetchAndUpdateProject: () => void;
    isLoadingNarrationUsage: boolean;
    fetchAndUpdateGroup: () => Promise<void>;
    fetchAndUpdateGroupAfterCreditUpdate: () => void;
    updateUserDisplayName: (displayName: string) => void;
    suggestedRssFeed: string | null | undefined;
    hosts: HostInterface[] | null | undefined;
    fetchAndUpdateHosts: () => void;
    setCoreWorkspaceDocuments: (uid?: string) => void;
}

export interface GlobalNarrationContextInterface {
    staticallyFetchedNarrations: NarrationInterface[];
    setStaticallyFetchedNarrations: Dispatch<SetStateAction<NarrationInterface[]>>;
    successAndDraftNarrations: NarrationInterface[] | undefined;
    successNarrations: NarrationInterface[] | undefined;
    successNarrationsLast30Days: NarrationInterface[] | undefined;
    draftNarrations: NarrationInterface[] | undefined;
    audioStudioNarrations: NarrationInterface[] | undefined;
    calendlySectionRef: any;
    mutateNarrations: () => void;
}

export interface VoiceDataContextInterface {
    voices: VoiceInterface[];
    languageAccentsMap: LanguageAccentsMapType;
    uniqueLanguages: SupportedISO639LanguagesType[];
}

export interface AudioStudioContextInterface extends UseStepsReturn {
    title: string;
    setTitle: Dispatch<SetStateAction<string>>;
    description: string;
    setDescription: Dispatch<SetStateAction<string>>;
    bodyText: string;
    setBodyText: Dispatch<SetStateAction<string>>;
    blocks: NarrationBlockInterface[];
    setBlocks: Dispatch<SetStateAction<NarrationBlockInterface[]>>;
    defaultVoice: string;
    setDefaultVoice: Dispatch<SetStateAction<string>>;
    audioUrl: string | null;
    setAudioUrl: Dispatch<SetStateAction<string | null>>;
    imageUrl: string | null;
    setImageUrl: Dispatch<SetStateAction<string | null>>;
    highestAvailableStep: number;
    wordsmithParameters: WordsmithParametersInterface;
    setWordsmithParameters: Dispatch<SetStateAction<WordsmithParametersInterface>>;
    runtimeDuration: number | null;
    setRuntimeDuration: Dispatch<SetStateAction<number | null>>;
    narrationDocumentId: FirestoreDocumentIdType | null;
    setNarrationDocumentId: Dispatch<SetStateAction<FirestoreDocumentIdType | null>>;
    sourceUrl: string | null;
    setSourceUrl: Dispatch<SetStateAction<string | null>>;
    contentSourceType: string;
    setContentSourceType: Dispatch<SetStateAction<string>>;
    updateBlock: (index: number, property: keyof NarrationBlockInterface, value: any) => void;
    deleteBlock: (index: number) => void;
    narrationDocumentStatus: StatusEnum | undefined;
    setNarrationDocumentStatus: Dispatch<SetStateAction<StatusEnum | undefined>>;
    language: LanguageDropedownValueType;
    setLanguage: Dispatch<SetStateAction<LanguageDropedownValueType>>;
    hasAutoSubmitted: boolean;
    setHasAutoSubmitted: Dispatch<SetStateAction<boolean>>;
    selectedFiles: NarrationInterface[];
    setSelectedFiles: Dispatch<SetStateAction<NarrationInterface[]>>;
    summarizeKeyPoints: string;
    setSummarizeKeyPoints: Dispatch<SetStateAction<string>>;
    summarizeLength: SUMMARIZE_LENGTH_DROP_DOWN_TYPE;
    setSummarizeLength: Dispatch<SetStateAction<SUMMARIZE_LENGTH_DROP_DOWN_TYPE>>;
    duration: number;
    setDuration: Dispatch<SetStateAction<number>>;
    freePreviewCharsAvailable: number | null;
    setFreePreviewCharsAvailable: Dispatch<SetStateAction<number | null>>;
    additionalAudioCharges: AdditionalAudioChargesInterface[];
    setAdditionalAudioCharges: Dispatch<SetStateAction<AdditionalAudioChargesInterface[]>>;
    requiredCreditsToGenerateAudio: number;
    selectedHosts: HostInterface[]; // * this is a partial because accounts without branding will not have all the host info
    setSelectedHosts: Dispatch<HostInterface[]>;
    desiredLengthMinutes: number | null;
    setDesiredLengthMinutes: Dispatch<SetStateAction<number | null>>;
    instructionsPrompt: string;
    setInstructionsPrompt: Dispatch<SetStateAction<string>>;
    finalCta: string;
    setFinalCta: Dispatch<SetStateAction<string>>;
    narrationMethod: NarrationMethodEnum;
    setNarrationMethod: Dispatch<SetStateAction<NarrationMethodEnum>>;
    prospectName: string;
    setProspectName: Dispatch<SetStateAction<string>>;
    highlightedValueProp: string;
    setHighlightedValueProp: Dispatch<SetStateAction<string>>;
    publishDate: NarrationInterface['publishDate'];
    setPublishDate: Dispatch<SetStateAction<NarrationInterface['publishDate']>>;
}

export interface NewPodcastPayload {
    organisationId: Number;
    title: string;
    description: string;
    email: string;
    cover_art: string;
    author: string;
    copyright: string;
    language: string;
    category_1: string;
    category_2: string;
    explicit: string;
    site_url: string;
}

export interface PodcastMetadataInterface {
    name: PodcastNameType;
    image: string;
    link: string;
    listenOnImage: string;
    icon: string;
    color?: string;
    tutorialVideoSrc?: string;
    tutorialVideoSrcMobile?: string;
    doneButtonText?: string;
    isUnderMaintenance?: boolean;
}

export interface UTMInterface {
    source: string | null;
    medium: string | null;
    campaign: string | null;
    content: string | null;
}

export interface UTMQueryInterface {
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
    utm_content?: string;
}
export interface PublisherInfoInterface {
    publicationName: string;
    imageSrc: string;
    description: string;
    subscriptionLink: string | null;
    useCategories?: boolean; // used for orgs that have multiple categories/feeds
    authRequired?: boolean;
    relatedContentKeys?: RELATED_CONTENT_KEYS[];
}

export interface BaseChildrenProps {
    children: ReactNode;
}

export enum ALL_ROUTES {
    AUTH_PAGE = '/',
    HOME = '/home',
    AUTO_NARRATIONS = '/integrations/auto-narrations',
    HUBSPOT_INTEGRATION = '/integrations/hubspot',
    HUBSPOT_SUCCESS = '/integrations/hubspot/success',
    CRM_INTEGRATION = '/integrations/crm',
    AUDIO_CATALOGUE = '/audio-catalogue',
    ANALYTICS = '/analytics',
    ONBOARDING = '/onboarding/welcome',
    PRONUNCIATION_LIBRARY = '/pronunciation-library',
    AUDIO_STUDIO = '/audio-studio',
    AUDIO_STUDIO_VERBATIM = '/audio-studio/verbatim',
    AUDIO_STUDIO_SCRIPTED = '/audio-studio/scripted',
    AUDIO_STUDIO_RUNDOWN = '/audio-studio/rundown',
    AUDIO_STUDIO_SHORTS = '/audio-studio/shorts',
    AUDIO_STUDIO_FILE_UPLOAD = '/audio-studio/upload',
    AUDIO_STUDIO_TWO_SPEAKER_PODCAST = '/audio-studio/two-speaker-podcast',
    AUDIO_STUDIO_LEAD_GEN = '/audio-studio/lead-gen',
    SHARE_NARRATION = '/share/[projectId]/[narrationId]',
    RESET_PASSWORD = '/reset',
    SIGNIN = '/signin',
    SIGNUP = '/signup',
    INTEGRATIONS = '/integrations',
    SETTINGS = '/settings',
    BRANDING = '/branding',
    MOTIVATION_2025 = '/2025-motivation',
    VALENTINES_DAY = '/valentines-day',
    EMBEDDABLE_PLAYER = '/integrations/embeddable-player',
    PODCASTS = '/integrations/podcasts',
    CSV_IMPORT = '/integrations/csv-import'
}

export interface SubscriptionInterface {
    tier: PLAN_VALUE;
    status: string;
    frequency: Frequencies;
    validThrough: FirestoreTimestampType | null;
    trialStart: FirestoreTimestampType | null;
    trialEnd: FirestoreTimestampType | null;
    isTrial: boolean;
}

// * this is a simplified interface so we don't have the pull in the entire Price object from
// * the Firestore-Stripe extension. If we need additional properties from the database, it's
// * easy enough to extend this interface to accomodate as needed
// * Note that the id field is not mandatory as we will not be storing info on the free tier in Stripe
export interface PriceFromStripeInterface {
    id?: string;
    unit: number;
}

export interface ProductTiersInterface {
    frequency: Record<keyof typeof Frequencies, PriceFromStripeInterface>;
}

export type ProductTiersType = Record<keyof typeof PLAN_VALUE, ProductTiersInterface>;

export interface MetaTagsInterface {
    title: string;
    description: string;
    ogTitle: string;
    ogDescription: string;
    ogImage: string;
}

export interface RelatedContentInterface {
    link: string;
    imageSrc: string;
}

export enum RELATED_CONTENT_KEYS {
    PLANET_MONEY = 'PLANET_MONEY',
    BUSINESS_CASUAL = 'BUSINESS_CASUAL',
    YOUR_MONEY_BRIEFING = 'YOUR_MONEY_BRIEFING',
    FREAKENOMICS = 'FREAKENOMICS',
    ANDERSON_COOPER = 'ANDERSON_COOPER',
    OLOGIES = 'OLOGIES',
    PARTIALLY_EXAMINED_LIFE = 'PARTIALLY_EXAMINED_LIFE',
    INFINITE_MONKEY_CAGE = 'INFINITE_MONKEY_CAGE',
    HOW_I_BUILT_THIS = 'HOW_I_BUILT_THIS',
    WORKLIFE = 'WORKLIFE',
    UPFIRST = 'UPFIRST',
    BIGGERPOCKETS_MONEY = 'BIGGERPOCKETS_MONEY',
    DAVE_RAMSEY_SHOW = 'DAVE_RAMSEY_SHOW',
    CLARK_HOWARD_PODCAST = 'CLARK_HOWARD_PODCAST',
    HBR_IDEACAST = 'HBR_IDEACAST',
    MASTERS_OF_SCALE = 'MASTERS_OF_SCALE',
    REVISIONIST_HISTORY = 'REVISIONIST_HISTORY',
    RADIOLAB = 'RADIOLAB'
}

export enum NarrationStatusEnum {
    Success = 'SUCCESS',
    Failure = 'FAILURE',
    Pending = 'PENDING',
    Debug = 'DEBUG'
}

export interface UserPreferencesInterface {
    age?: string | null;
    goal?: PERSONAL_GOAL_ENUM | null;
    language?: LanguageCodeType | null;
    listeningActivity?: LISTENING_ACTIVITY_ENUM | null;
    listeningTime?: LISTENING_TIME_ENUM | null;
    primaryInterest?: PRIMARY_INTEREST_ENUM | null;
}

export interface PodcastLinksInterface {
    spotify?: string;
    apple?: string;
    youtube?: string;
}

export interface UserPropertyInfoInterface {
    name: string;
    uid?: string;
    podcastLinks?: PodcastLinksInterface;
}
export type UserPropertyMapType = Record<string, UserPropertyInfoInterface>;

export interface UrlInstructionsConfigInterface {
    bulletPoints: { name: string | ReactNode; imageSrc?: string }[];
    title: string;
    link: { name: string; position: 'flex-end' | 'flex-start' };
    containerColor: string;
    fontColor: string;
}

export interface NarrationRequestActionInterface {
    type: 'ADD' | 'REMOVE';
    timestamp: string;
    title: string;
}

// * ADR for PodcastSetup: https://www.notion.so/play-auris/ADR-Podcast-Setup-Collection-b69c1de61f64422488c56a6ee53ea2d6
export type PodcastPlatformUrl = string | null;
export enum PodcastSetupStatusEnum {
    'ACTIVE' = 'ACTIVE',
    'INCOMPLETE' = 'INCOMPLETE',
    'UNBEGUN' = 'UNBEGUN'
}
export interface PodcastSetupStatusInterface {
    spotifyStatus: PodcastSetupStatusEnum;
    appleStatus: PodcastSetupStatusEnum;
    googleStatus: PodcastSetupStatusEnum;
}
export interface PodcastSetupInterface extends PodcastSetupStatusInterface {
    spotifyUrl: PodcastPlatformUrl;
}

export interface UserPreferencesPodcastSetupInterface {
    uid: string;
    spotifyUrl?: PodcastPlatformUrl;
    spotifyStatus?: PodcastSetupStatusEnum;
    appleStatus?: PodcastSetupStatusEnum;
    googleStatus?: PodcastSetupStatusEnum;
    otherActiveUserAgents?: string[]; // array of detected user agents from other platforms
}

export interface FeatureFlagsContextInterface {
    allowTransformedAudio: FeatureFlagContextStateType;
    allowSectionedNarrationForm: FeatureFlagContextStateType;
    hideAutoNarrations: FeatureFlagContextStateType;
    allowYoutubeIntegrationV1: FeatureFlagContextStateType;
    showNarrationShareButton: FeatureFlagContextStateType;
    hasDistributedWidget: FeatureFlagContextStateType;
    setHasDistributedWidget: FeatureFlagContextSetStateType;
    hasSharedOnSocialMedia: FeatureFlagContextStateType;
    setHasSharedOnSocialMedia: FeatureFlagContextSetStateType;
    hasDownloadedAudio: FeatureFlagContextStateType;
    setHasDownloadedAudio: FeatureFlagContextSetStateType;
    bypassPaymentPortal: FeatureFlagContextStateType;
    allowUnlimitedAudioStudio: FeatureFlagContextStateType;
    isEnabledAudioStudio: boolean;
    refreshAllFeatureFlags: () => void;
    paymentTierAccessLevel: number | undefined;
    isAtCapacityOnAudioStudio: boolean | undefined;
    usedAudioStudioNarrationCapacity: number;
    availableAudioStudioNarrationCapacity: number;
    paymentTierName: string;
    isFeaturePaywalled: Record<PAYWALLED_FEATURES, boolean | undefined>;
    setAllowUnlimitedAudioStudio: FeatureFlagContextSetStateType;
    setHasVisitedPlansAndPricing: Dispatch<SetStateAction<boolean>>;
    hasSkippedTutorial: boolean;
    setHasSkippedTutorial: Dispatch<SetStateAction<boolean>>;
    isUnlockAllAnalytics: FeatureFlagContextStateType;
    showRewriteScript: FeatureFlagContextStateType;
    allowSonicIdentity: FeatureFlagContextStateType;
    isAdaurisAdmin: FeatureFlagContextStateType;
    unlockEnterpriseFeatures: FeatureFlagContextStateType;
    allowUnlimitedHosts: FeatureFlagContextStateType;
    allowCustomTemplates: FeatureFlagContextStateType;
    allowLeadGen: FeatureFlagContextStateType;
    displayCrmIntegrations: FeatureFlagContextStateType;
    allowCsvImport: FeatureFlagContextStateType;
}

export interface WidgetSettingsInterface {
    primaryColor: string | null;
    primaryPresetTheme: 'light' | 'dark' | 'detect'; //* Legacy used yingyang, dark, light, transparent - light is now default on widget
    hideAALogoInRestingState: boolean;
    backgroundColor: string | null;
    hideBorder?: boolean;
    restingHeading?: string;
    activeHeading?: string;
}

export interface ShareSettingsCtaSectionInterface {
    heading: string;
    linkHref: string;
    linkText: string;
}

export interface ProjectShareSettingsInterface {
    defaultPrivate: boolean;
    disableDiscovery: boolean;
    ctaSection?: ShareSettingsCtaSectionInterface;
}

export interface ProjectInterface {
    id: FirestoreDocumentIdType;
    creationDate: FirestoreTimestampType;
    modifiedDate: FirestoreTimestampType;
    groupId: FirestoreDocumentIdType; // Group Collection ID
    name: string;
    routeIdentifier?: UrlType; // ! deprecated. This property is no longer added to new projects as of July 12 2024, but it is still present in existing projects
    widgetSettings: WidgetSettingsInterface;
    subscriptionLink: UrlType | null;
    isEnabled: boolean; //false - user out of free trial
    version: number;
    podcastLinks?: PodcastLinksInterface;
    shareSettings?: ProjectShareSettingsInterface;
    description?: string;
}

export interface UserGroupInterface {
    id: FirestoreDocumentIdType;
    groupId: FirestoreDocumentIdType; //Group Collection ID
    creationDate: FirestoreTimestampType;
    modifiedDate: FirestoreTimestampType;
    version: number;
}

interface PurchasesInterface {
    audioStudioNarrations: {
        totalPurchased: number; // cumulative number of purchased narrations for data records
        currentAllocation: number; // number of allocated audio studio narrations
    };
    autoNarrations: {
        totalPurchased: number; // cumulative number of purchased narrations for data records
        currentAllocation: number; // number of allocated audio studio narrations
    };
}

export interface GroupInterface {
    id: FirestoreDocumentIdType;
    creationDate: FirestoreTimestampType;
    modifiedDate: FirestoreTimestampType;
    name: string;
    users: FirestoreDocumentIdType[]; // user ids INCLUDING adminUser id
    adminUser: FirestoreDocumentIdType; // admin userId
    version: number;
    siteUrl: UrlType;
    narrationUsage: PurchasesInterface;
    narrationSettingsId?: FirestoreDocumentIdType; // introduced in version = 4 of the Group documents, so optional, but should be included moving forward
    brandVoice?: Record<string, any> | null; // implemented in version 5
    managedProjectIds?: FirestoreDocumentIdType[]; // implemented in version 6
    apiKey?: string; // implemented in version 6
}

export interface CmsIntegrationSettingsRssOptions {
    url: UrlType;
}
/*
* Both of the following example responses from Hubspot are added together to create this type:
access token result {
 "accessToken": "CLD89vSSMhICAgEYi4moFiCPidsgKNm32gEyFPyncKfdCzyuHWkeAW1ZwYBOrRaoOlAAMQBB_wcAAAAAgAAAYHjOKAAAACAAACA0AAAYAAAAwMMfBgEAAACAJwAA4AAAAAAAAAAAAAAAAAAAAgAIuAIAAAAAAAAAAAAAAAAAAAAAQEIUU4BzH0otHRDpCt2E2NVRZX4-2jZKA25hMVIAWgBgAA",
 "expiresIn": 1800,
 "refreshToken": "9a32234e-a0b0-4d00-b2fb-6467497a29d0",
 "tokenType": "bearer"
}
Access token info response: AccessTokenInfoResponse {
  token: 'CLD89vSSMhICAgEYi4moFiCPidsgKNm32gEyFPyncKfdCzyuHWkeAW1ZwYBOrRaoOlAAMQBB_wcAAAAAgAAAYHjOKAAAACAAACA0AAAYAAAAwMMfBgEAAACAJwAA4AAAAAAAAAAAAAAAAAAAAgAIuAIAAAAAAAAAAAAAAAAAAAAAQEIUU4BzH0otHRDpCt2E2NVRZX4-2jZKA25hMVIAWgBgAA',
  user: 'griffin.cook@adauris.ai',
  hubDomain: 'Developer test account 2-dev-46793867.com',
  scopes: [ 'content', 'oauth' ],
  hubId: 46793867,
  appId: 3578841,
  expiresIn: 1799,
  userId: 68600975,
  tokenType: 'access'
}
Final Conctatenated response {
  accessToken: 'CLD89vSSMhICAgEYi4moFiCPidsgKNm32gEyFPyncKfdCzyuHWkeAW1ZwYBOrRaoOlAAMQBB_wcAAAAAgAAAYHjOKAAAACAAACA0AAAYAAAAwMMfBgEAAACAJwAA4AAAAAAAAAAAAAAAAAAAAgAIuAIAAAAAAAAAAAAAAAAAAAAAQEIUU4BzH0otHRDpCt2E2NVRZX4-2jZKA25hMVIAWgBgAA',
  expiresIn: 1799,
  refreshToken: '9a32234e-a0b0-4d00-b2fb-6467497a29d0',
  tokenType: 'access',
  token: 'CLD89vSSMhICAgEYi4moFiCPidsgKNm32gEyFPyncKfdCzyuHWkeAW1ZwYBOrRaoOlAAMQBB_wcAAAAAgAAAYHjOKAAAACAAACA0AAAYAAAAwMMfBgEAAACAJwAA4AAAAAAAAAAAAAAAAAAAAgAIuAIAAAAAAAAAAAAAAAAAAAAAQEIUU4BzH0otHRDpCt2E2NVRZX4-2jZKA25hMVIAWgBgAA',
  user: 'griffin.cook@adauris.ai',
  hubDomain: 'Developer test account 2-dev-46793867.com',
  scopes: [ 'content', 'oauth' ],
  hubId: 46793867,
  appId: 3578841,
  userId: 68600975,
  updatedAt: 1723062175736
}
*/
export interface CmsIntegrationSettingsHubspotOptions {
    accessToken: string;
    expiresIn: number; // seconds. Starts at 1800 and goes down
    refreshToken: string;
    tokenType: string; // likely "access"
    updatedAt: string; // updatedAt is not from Hubspot, its custom addition from our side
    token: string;
    user: string;
    hubDomain: string;
    scopes: string[];
    hubId: number;
    appId: number;
    userId: number;
}

export interface CmsIntegrationSettingsUnifiedOptions {
    connectionId: string;
    platform: string;
    cohortIdentifiers: { key: string; value: string; comparisonOperator: '=' | '!=' | '>' | '<' | '>=' | '<=' }[];
    cohortIdentifierClause: 'AND' | 'OR';
}

export enum CmsIntegrationSettingsMethodNameEnum {
    RSS = 'RSS',
    Hubspot = 'Hubspot',
    Unified = 'Unified'
}

export interface CmsIntegrationSettings {
    id: FirestoreDocumentIdType;
    creationDate: FirestoreTimestampType;
    modifiedDate: FirestoreTimestampType;
    groupId?: FirestoreDocumentIdType; // introduced in version = 2 of the CMS integration settings documents, so optional, but should be included moving forward
    projectId: FirestoreDocumentIdType;
    method: CmsIntegrationSettingsMethodNameEnum;
    isEnabled: boolean; //true/false - not needed on v1 really
    narrationSettingsId: FirestoreDocumentIdType;
    version: number;
    options?: CmsIntegrationSettingsRssOptions | CmsIntegrationSettingsHubspotOptions | CmsIntegrationSettingsUnifiedOptions;
    fetchedNarrationSettings?: NarrationSettingsInterface; // this won't come from the BE but can be attached to CmsIntergrationSettings after fetching with narrationSettingsId
    name?: string;
}

export interface UnifiedIntegrationSettingsInterface extends CmsIntegrationSettings {
    options?: CmsIntegrationSettingsUnifiedOptions;
    method: CmsIntegrationSettingsMethodNameEnum.Unified;
}

// * The Required partial makes the optional params from CmsIntergrationSettings required
export interface RSSIntegrationSettings extends CmsIntegrationSettings {
    options?: CmsIntegrationSettingsRssOptions;
    method: CmsIntegrationSettingsMethodNameEnum.RSS;
    fetchedNarrationSettings: NarrationSettingsInterface;
    transientRssFeedUrl?: string | null; // * this is a temporary field that is used to store the RSS feed URL when the user is creating a new RSS integration
}

export interface HubspotIntegrationSettings extends CmsIntegrationSettings {
    options?: CmsIntegrationSettingsHubspotOptions;
    method: CmsIntegrationSettingsMethodNameEnum.Hubspot;
}
export interface NarrationSettingsInterface {
    id?: FirestoreDocumentIdType;
    groupId: FirestoreDocumentIdType;
    creationDate?: FirestoreTimestampType;
    modifiedDate?: FirestoreTimestampType;
    name: NarrationSettingsNameType;
    bodyVoice: VoiceCodeType;
    titleVoice: VoiceCodeType;
    intro: string | null;
    introText: string | null;
    outro: string | null;
    outroText: string | null;
    language: LanguageDropedownValueType;
    wordList: NarrationSettingsWordListType;
    sonicIdentity?: Record<string, any> | null;
    hostIds?: FirestoreDocumentIdType[];
    rewritePrompt?: {
        baseNarrationMethod: NarrationMethodEnum;
        customPrompt?: string;
        desiredLengthMinutes?: number;
        sellerHighlightedValueProp?: string;
        finalCta?: string;
        excludeBranding?: boolean;
    };
    defaultImageUrl?: string;
    breakTimes: {
        title: string | null; // SSML string
        paragraph: string | null; // SSML string
        quote: string | null; // SSML string
        outro: number | null;
    };
    readQuoteMarks: boolean;
    version: number;
}
export type NarrationSettingsPayloadInterface = Omit<NarrationSettingsInterface, 'id' | 'creationDate' | 'modifiedDate'>;

export interface CaptureProperties {
    distinctId: any;
    event: any;
    properties: any;
    groups?: {
        [key: string]: any;
    };
}

export interface PodcastRssFormInterface {
    title: string;
    language: string;
    description: string;
    author: string;
    email: string;
    copyright: string;
    category1: CATEGORY_TYPE;
    category2: string;
    explicit: boolean;
    coverArt: UrlType;
    site: UrlType;
}

export interface PodcastChannelPayloadInterface extends PodcastRssFormInterface {
    projectId: FirestoreDocumentIdType;
}

export interface PodcastChannelInterface extends PodcastChannelPayloadInterface {
    id: FirestoreDocumentIdType;
    version: DocumentVersionType;
    creationDate: FirestoreTimestampType;
    modifiedDate: FirestoreTimestampType;
}

export enum LanguageEnum {
    en = 'English',
    ar = 'Arabic',
    zh = 'Chinese Simplified',
    nl = 'Dutch',
    tl = 'Filipino',
    fr = 'French',
    de = 'German',
    hi = 'Hindi',
    pt = 'Portuguese',
    ru = 'Russian',
    es = 'Spanish',
    ta = 'Tamil',
    ja = 'Japanese',
    ko = 'Korean',
    it = 'Italian'
}

export type ISO639 = keyof typeof LanguageEnum;

// * NOTE: We will have to adjust this whenever we add a new language to our voices collection
export type SupportedISO639LanguagesType = Exclude<ISO639, 'ta' | 'ru' | 'tl'>;

export type SupportedSampleTextAudioLanguagesType = Extract<ISO639, 'en' | 'es' | 'pt' | 'fr'>;

export type LanguageAccentsMapType = Record<SupportedISO639LanguagesType, AccentEnum[]>;
export type AccentFlagMapType = Record<AccentEnum, string>;

// * the values match values in Firestore
export enum VoiceGenderEnum {
    male = 'Male',
    female = 'Female'
}

// * the values match values in Firestore
export enum AccentEnum {
    american = 'American',
    british = 'British',
    australian = 'Australian',
    austrian = 'Austrian',
    brazilian = 'Brazilian',
    canadian = 'Canadian',
    china = 'China',
    dutch = 'Dutch',
    filipino = 'Filipino',
    french = 'French',
    german = 'German',
    indian = 'Indian',
    italian = 'Italian',
    japanese = 'Japanese',
    korean = 'Korean',
    mexican = 'Mexican',
    portuguese = 'Portuguese',
    saudi = 'Saudi',
    spanish = 'Spanish',
    swiss = 'Swiss'
}

export interface Labels {
    accent: AccentEnum;
    gender: VoiceGenderEnum;
    tier: Tier;
    [key: string]: string; // Allows any number of additional properties
}

export enum providerEnum {
    azure = 'azure',
    elevenLabs = 'elevenLabs',
    openAI = 'openAI',
    unrealSpeech = 'unrealSpeech',
    google = 'google',
    cartesia = 'cartesia'
}

export type Tier = 'PREMIUM' | 'STANDARD';

export const tierMapping = {
    PREMIUM: true,
    STANDARD: false
};

export interface VoiceInterface {
    id: FirestoreDocumentIdType;
    voiceCode: VoiceCodeType;
    name: string;
    labels: Labels;
    language: ISO639LanguageCode[];
    audioSampleUrl: UrlType | null;
    provider: providerEnum;
    isPremium?: boolean;
    allowedGroupIds?: FirestoreDocumentIdType[];
}

export interface PodcastLanguageDataInterface {
    name: string;
    voiceCodes: VoiceInterface[];
}

export interface PosthogChartDataInterface {
    date: number;
    dataPoint: number;
}
export type PosthogChartDataArrayType = PosthogChartDataInterface[];

export interface CumulativeDataInterface {
    articleTitle: string;
    listenData: number[];
}

export interface Top10NarrationsCumulativeDataInterface {
    labels: string[];
    data: CumulativeDataInterface[];
}

export enum ThumbFeedbackEnum {
    Upvote = 'Upvote',
    Downvote = 'Downvote'
}

export interface AudioTrackOptionInterface {
    label: string;
    audioUrl: string | null;
    lengthInSeconds: number | null;
}

// * Taken from the splash-page app
export interface BaseNarrationSharePageProps {
    projectId: string | null;
    narrationId: string | null;
    sharedNarration: NarrationInterface | null;
}

export interface SpotlightGrowthPageInterface {
    id?: string;
    publicationName: string;
    headerText: string;
    subtext?: string;
    textBeforeNarrationsLine1: string;
    textBeforeNarrationsLine2: string;
    buttonText?: string;
    mainImageSrc: string;
    narration1: NarrationInterface;
    sampleNarrations: NarrationInterface[];
    playerColour?: string;
}

export interface RssFeedFinderResponseInterface {
    isValidUrl: boolean;
    rssUrl: string;
}

export interface PaymentsFeaturesInterface {
    featureName: string | ReactNode;
    tooltipText?: string;
    paywalledFeature?: PAYWALLED_FEATURES;
}

export interface DiffbotData {
    title: string;
    text: string;
}

export type CharsByVoiceType = Record<string, number>;

export interface CharStatsInterface {
    totalOriginalChars: number;
    totalCompiledChars: number;
    // If edits, cleaning, other modifications made, then that reflects compiled, which is final chars before TTS conversion
    totalCharsByVoice: CharsByVoiceType;
}

export interface AudioCreationDataInterface {
    audioUrl: UrlType;
    charStats: CharStatsInterface;
    duration: number;
    blockResponses?: NarrationBlockInterface[];
}

export function isAudioCreationDataInterface(data: any): data is AudioCreationDataInterface {
    return (data as AudioCreationDataInterface).audioUrl !== undefined;
}

export enum CLOUD_PROVIDER_ENUM {
    GCP = 'GCP',
    AWS = 'AWS'
}

export interface CoreWorkspaceDocumentsInterface {
    group: GroupInterface;
    narrationSettings: NarrationSettingsInterface;
    project: ProjectInterface;
}

export interface GetCheckoutSessionUrlPayloadParams {
    uid: FirestoreDocumentIdType;
    price: string;
    successUrl: string;
    cancelUrl: string;
    trialPeriodDays?: number;
    // * clientReferenceId is used for rewardful. https://github.com/rewardful/examples/blob/master/next-ts-page-router/pages/api/checkout_sessions.ts
    clientReferenceId?: string;
}

export interface TwoSpeakerPodcastResponseInterface {
    blocks: {
        text: string;
        block_type: string;
        host_name: string;
    }[];
}

// * extend generic Record<string, any> to allow for any additional properties as we iterate on this feature
export interface HostInterface extends Record<string, any> {
    id?: string;
    groupId?: string;
    name: string;
    voiceCode: string;
}

export interface ProductApiNarrationsResponseInterface {
    audioUrl: string;
    iframeEmbedCode: string;
    sharePageUrl: string;
}

export interface ProductApiBrandingResponseInterface {
    brandVoice: Record<string, any>;
    hosts: Record<string, any>[];
}

export interface ProductApiNarrationPayloadInterface {
    source: string;
    projectId: string;
    title?: string;
    image?: string;
    introAudioSrc?: string;
    intro?: {
        text: string;
        voice: string;
        backgroundTrackSrc?: string;
    };
    outroAudioSrc?: string;
    outro?: {
        text: string;
        voice: string;
        backgroundTrackSrc?: string;
    };
    webhookUrl?: string;
    excludeBranding?: boolean;
    maxCharacters?: number;
}

export interface ProductApiVerbatimNarrationPayloadInterface extends ProductApiNarrationPayloadInterface {
    voice: string;
}

export interface ProductApiCustomTemplatePayloadInterface extends ProductApiNarrationPayloadInterface {
    templateId: string;
}

export interface ProductApiSingleSpeakerPodcastNarrationPayloadInterface extends ProductApiNarrationPayloadInterface {
    voice: string;
}

export interface ProductApiVoiceNoteFromProspectPayloadInterface extends Omit<ProductApiNarrationPayloadInterface, 'source'> {
    voice: string;
    draftVoiceNoteScript: string;
    prospectInformation: Record<string, any>;
    salesCompanyDetails: Record<string, any>;
    salesRepDetails: Record<string, any>;
    verbatimPhrases: string[];
}

export interface ProductApiValentineFortuneNarrationPayloadInterface {
    projectId: string;
    userName: string;
    partnerName: string;
    userInterest: string;
    partnerInterest: string;
    meetingStory: string;
}

export interface ProductApiTwoSpeakerPodcastNarrationPayloadInterface extends ProductApiNarrationPayloadInterface {
    hosts?: HostInterface[] | null;
    customPrompt?: string;
    desiredLengthMinutes?: number;
}

export interface BuyerInformationInterface {
    name?: string;
    about?: string;
}
export interface ProductApiTwoSpeakerLeadGenNarrationPayloadInterface extends Omit<ProductApiTwoSpeakerPodcastNarrationPayloadInterface, 'source'> {
    buyerInformation: BuyerInformationInterface;
    sellerInformation?: Record<string, any>;
    sellerHighlightedValueProp?: string;
}

export interface ProductApiTwoSpeakerMotivationalNarrationPayloadInterface extends Omit<ProductApiNarrationPayloadInterface, 'source'> {
    hosts: HostInterface[] | null;
    listenerGoals: string;
    listenerInfo: string;
}

export interface ProductApiTwoSpeakerValentinesDayNarrationPayloadInterface extends Omit<ProductApiNarrationPayloadInterface, 'source'> {
    hosts: HostInterface[] | null;
    userName: string;
    userInterest: string[];
    partnerName: string;
    partnerInterest: string[];
    meetingStory: string;
}

export interface CreateBrandingPayloadInterface {
    projectId: FirestoreDocumentIdType;
    siteUrl: string;
    additionalBrandInfo?: string;
}

export interface SuggestedVoiceInterface {
    voiceCode: string;
    justification: string;
}
