import { captureException } from '@sentry/nextjs';
import { FirestoreDocumentIdType, NarrationInterface, StatusEnum } from '../types/types';
import { formatSentryMessage } from './telemetryHelpers';
import { getIsPublishedNarration } from './narrationHelpers';

export const isGoogleDoc = (urlInput: string) => {
    let urlObj;
    try {
        urlObj = new URL(urlInput);
    } catch (e) {
        return false;
    }

    const host = urlObj.hostname.toLowerCase();
    const docHost = 'docs.google.com';

    if (host === docHost) {
        return true;
    }

    return false;
};

export const isUrl = (urlInput: string) => {
    try {
        new URL(urlInput);
    } catch (_) {
        return false;
    }

    return true;
};

export const isInvalidUrl = (url: string) => {
    return !isUrl(url);
};

export const isEbook = (url: string) => {
    return url.lastIndexOf('.epub') > 0;
};

export const isPdf = (url: string) => {
    return url.lastIndexOf('.pdf') > 0;
};

export const isPdfBlocked = (url: string, isDisabled?: boolean) => {
    return !!isDisabled && isPdf(url);
};

export const isMissingHttp = (url: string) => {
    return !!url && !url.includes('http://') && !url.includes('https://');
};

export const isDoi = (url: string) => {
    return url.includes('/doi') || url.includes('doi:');
};

export const isPodcastLink = (url: string) => {
    return url.includes('podcasts.ad-auris');
};

export const isAdAurisLink = (url: string) => {
    return url.includes('play.ad-auris');
};

export const isGmailLink = (url: string) => {
    return url.includes('mail.google.com');
};

export const isRedditLink = (url: string) => {
    return url.includes('reddit.com');
};

export const isYoutubeLink = (url: string) => {
    return url.includes('youtube.com');
};

export const isLinkedInLink = (url: string) => {
    return url.includes('linkedin.com');
};

export const isTwitterLink = (url: string) => {
    return url.includes('twitter.com');
};

export const normalizeUrlComponent = (url?: string | null, fallback: string = 's') => {
    if (!url) return fallback;

    const normalized = url
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
        .replace(/[^a-z0-9\s-]/g, '') // Remove non-alphanumeric chars (except spaces and dashes)
        .trim()
        .replace(/\s+/g, '-') // Replace spaces with dashes
        .replace(/-+/g, '-') // Replace multiple dashes with single dash
        .replace(/^-+|-+$/g, ''); // Remove leading/trailing dashes

    // If string is empty after normalization, return fallback
    if (!normalized) return fallback;

    // Limit length while avoiding cutting in the middle of a word
    const MAX_LENGTH = 50;
    if (normalized.length <= MAX_LENGTH) return normalized;

    const truncated = normalized.slice(0, MAX_LENGTH);
    const lastDashIndex = truncated.lastIndexOf('-');
    const result = lastDashIndex > 0 ? truncated.slice(0, lastDashIndex) : truncated;
    return encodeURIComponent(result);
};

export const getPublishedNarrationShareUrl = (
    narrationId: FirestoreDocumentIdType | undefined | null,
    narrationStatus: StatusEnum | undefined,
    narrationTitle?: string | null
): string | null => {
    const baseNarrationWidgetUrl = process.env.NEXT_PUBLIC_BASE_NARRATION_WIDGET_URL;

    if (baseNarrationWidgetUrl && getIsPublishedNarration(narrationStatus) && narrationId) {
        const normalizedNarrationTitle = normalizeUrlComponent(narrationTitle, 's');
        return `${baseNarrationWidgetUrl}/${normalizedNarrationTitle}/listen/${narrationId}`;
    } else {
        if (!baseNarrationWidgetUrl) {
            const message = formatSentryMessage('Missing NEXT_PUBLIC_BASE_NARRATION_WIDGET_URL', 'getPublicNarrationShareUrl');
            captureException(new Error(message));
        }
        // * we want to return null if the narration is not ready to be shared.
        // * It can be valid that project, narrationDocId, or narrationStatus are not valid yet when this function is called.
        return null;
    }
};

export const getPublishedNarrationDistributionUrl = (
    projectId: FirestoreDocumentIdType | undefined | null,
    narrationId: FirestoreDocumentIdType | undefined | null,
    narrationStatus: StatusEnum | undefined
) => {
    const baseNarrationWidgetUrl = process.env.NEXT_PUBLIC_BASE_NARRATION_WIDGET_URL;

    if (baseNarrationWidgetUrl && getIsPublishedNarration(narrationStatus) && narrationId && projectId) {
        return `${baseNarrationWidgetUrl}/v2/widget/${projectId}/${narrationId}?distribution=true`;
    }

    // * we want to return null if the narration is not ready to be shared.
    // * It can be valid that project, narrationDocId, or narrationStatus are not valid yet when this function is called.
    return null;
};

export const getPublicNarrationSocialMediaUrl = (platformUrl: string, shareUrlParam: string | null) => {
    if (shareUrlParam) {
        return encodeURI(platformUrl + shareUrlParam);
    } else return '';
};
