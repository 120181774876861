import { captureException } from '@sentry/nextjs';
import axios from 'axios';
import {
    AuthContextInterface,
    CmsIntegrationSettingsHubspotOptions,
    CmsIntegrationSettingsMethodNameEnum,
    CmsIntegrationSettings,
    FirestoreDocumentIdType,
    GroupInterface,
    HubspotIntegrationSettings,
    NarrationSettingsInterface,
    ProjectInterface,
    RSSIntegrationSettings,
    UnifiedIntegrationSettingsInterface
} from '../types/types';
import {
    DEFAULT_HUBSPOT_INTEGRATION_SETTINGS,
    DEFAULT_RSS_INTEGRATION_SETTINGS,
    DEFAULT_UNIFIED_INTEGRATION_SETTINGS
} from '../utils/constants/audioContants';
import { deleteNarrationSettings, getNarrationSettingsById, patchNarrationSettings, postNarrationSettings } from './narrationSettingsHelpers';
import { formatSentryError } from './telemetryHelpers';
import { HUBSPOT_COOKIE_NAME } from '../utils/constants/constants';
import { postCmsIntegrationSettingsServerSide } from '../pages/api/v2/cms-integration-settings/post/index.api';

export const getAllCmsIntegrationsByProjectId = async (projectId: FirestoreDocumentIdType) => {
    try {
        const response = await axios.get(`/api/v2/cms-integration-settings/get/project-id/${projectId}`);
        return response.data as CmsIntegrationSettings[];
    } catch (err) {
        const { message, cause } = formatSentryError(
            'Error hitting next API cms-integration-settings/get/project-id',
            'getAllCmsIntegrationsByProjectId',
            err
        );
        captureException(new Error(message, cause));
        throw err;
    }
};

export const postRssIntegrationSettings = async (
    rssIntegrationSettingsPayload: Partial<RSSIntegrationSettings>,
    narrationSettingsPayload?: Partial<NarrationSettingsInterface>
) => {
    // * ensure method is RSS
    const cleanedCmsPayload = { ...rssIntegrationSettingsPayload, method: CmsIntegrationSettingsMethodNameEnum.RSS };
    return await postCmsIntegrationSettings(cleanedCmsPayload, narrationSettingsPayload);
};

export const postHubspotIntegrationSettings = async (
    hubspotIntegrationSettingsPayload: Partial<HubspotIntegrationSettings>,
    narrationSettingsPayload?: Partial<NarrationSettingsInterface>
) => {
    // * ensure method is Hubspot
    const cmsPayload = { ...hubspotIntegrationSettingsPayload, method: CmsIntegrationSettingsMethodNameEnum.Hubspot };
    return await postCmsIntegrationSettings(cmsPayload, narrationSettingsPayload);
};

export const postUnifiedIntegrationSettings = async (
    unifiedIntegrationSettingsPayload: Partial<UnifiedIntegrationSettingsInterface>,
    isServerSideRequest = false
) => {
    // * ensure method is Unified
    const cmsPayload = { ...unifiedIntegrationSettingsPayload, method: CmsIntegrationSettingsMethodNameEnum.Unified };
    return await postCmsIntegrationSettings(cmsPayload, undefined, isServerSideRequest);
};

export const postCmsIntegrationSettings = async (
    cmsIntegrationSettingsPayload: Partial<CmsIntegrationSettings>,
    narrationSettingsPayload?: Partial<NarrationSettingsInterface>,
    isServerSideRequest = false
) => {
    try {
        // * create new narration settings if existing settings aren't attached
        if (!cmsIntegrationSettingsPayload.narrationSettingsId && narrationSettingsPayload) {
            // * remove values that are defaults in operations-rest-api
            if (narrationSettingsPayload.id) {
                delete narrationSettingsPayload.id;
            }
            if (narrationSettingsPayload.creationDate) {
                delete narrationSettingsPayload.creationDate;
            }
            if (narrationSettingsPayload.modifiedDate) {
                delete narrationSettingsPayload.modifiedDate;
            }
            if (narrationSettingsPayload.version) {
                delete narrationSettingsPayload.version;
            }

            // * ensure name is Integration
            if (narrationSettingsPayload.name !== 'Integration') {
                narrationSettingsPayload.name = 'Integration';
            }

            const narrationSettingsId = await postNarrationSettings(narrationSettingsPayload);
            cmsIntegrationSettingsPayload.narrationSettingsId = narrationSettingsId;
        }

        if (cmsIntegrationSettingsPayload.method === 'RSS') {
            // * assign default values for RSS
            cmsIntegrationSettingsPayload = { ...DEFAULT_RSS_INTEGRATION_SETTINGS, ...cmsIntegrationSettingsPayload };
        } else if (cmsIntegrationSettingsPayload.method === 'Hubspot') {
            // * assign default values for Hubspot
            cmsIntegrationSettingsPayload = { ...DEFAULT_HUBSPOT_INTEGRATION_SETTINGS, ...cmsIntegrationSettingsPayload };
        } else if (cmsIntegrationSettingsPayload.method === 'Unified') {
            // * assign default values for Hubspot
            cmsIntegrationSettingsPayload = { ...DEFAULT_UNIFIED_INTEGRATION_SETTINGS, ...cmsIntegrationSettingsPayload };
        } else {
            throw new Error('Method not supported');
        }

        if (isServerSideRequest) {
            const cmsIntegrationSettingsId = await postCmsIntegrationSettingsServerSide(
                cmsIntegrationSettingsPayload as Omit<CmsIntegrationSettings, 'id'> & Partial<CmsIntegrationSettings>
            );
            return cmsIntegrationSettingsId;
        } else {
            const response = await axios.post('/api/v2/cms-integration-settings/post', cmsIntegrationSettingsPayload);
            return response.data as FirestoreDocumentIdType;
        }
    } catch (err) {
        console.error('error here griffin', err);
        const { message, cause } = formatSentryError('Error hitting next API cms-integration-settings/post/', 'postCmsIntegrationSettings', err);
        captureException(new Error(message, cause));
        throw err;
    }
};

interface PatchCmsIntegrationSettingsProps {
    cmsIntegrationSettingsId: FirestoreDocumentIdType;
    cmsIntegrationSettingsPayload: Partial<CmsIntegrationSettings>;
    narrationSettingsId?: FirestoreDocumentIdType;
    narrationSettingsPayload?: Partial<NarrationSettingsInterface>;
}
export const patchCmsIntegrationSettings = async ({
    cmsIntegrationSettingsId,
    cmsIntegrationSettingsPayload,
    narrationSettingsId,
    narrationSettingsPayload
}: PatchCmsIntegrationSettingsProps) => {
    try {
        // * patch narration settings that is already attached to cmsIntegrationSettings if narrationSettingsPayload is passed
        if (narrationSettingsId && narrationSettingsPayload) {
            await patchNarrationSettings(narrationSettingsId, narrationSettingsPayload);
        }
        const response = await axios.patch(`/api/v2/cms-integration-settings/patch/${cmsIntegrationSettingsId}`, cmsIntegrationSettingsPayload);
        return response.data as FirestoreDocumentIdType;
    } catch (err) {
        const { message, cause } = formatSentryError('Error hitting next API cms-integration-settings/patch/', 'patchCmsIntegrationSettings', err);
        captureException(new Error(message, cause));
        throw err;
    }
};

interface PatchRssIntegrationSettingsProps extends Omit<PatchCmsIntegrationSettingsProps, 'cmsIntegrationSettingsPayload'> {
    rssIntegrationSettingsPayload: Partial<RSSIntegrationSettings>;
}
export const patchRssIntegrationSettings = async ({
    cmsIntegrationSettingsId,
    rssIntegrationSettingsPayload,
    narrationSettingsId,
    narrationSettingsPayload
}: PatchRssIntegrationSettingsProps) => {
    try {
        // * ensure method is RSS
        const cmsIntegrationSettingsPayload: Partial<RSSIntegrationSettings> = {
            ...rssIntegrationSettingsPayload,
            method: CmsIntegrationSettingsMethodNameEnum.RSS
        };
        const documentId = await patchCmsIntegrationSettings({
            cmsIntegrationSettingsId,
            cmsIntegrationSettingsPayload,
            narrationSettingsId,
            narrationSettingsPayload
        });
        return documentId;
    } catch (err) {
        throw err;
    }
};

interface PatchHubspotIntegrationSettingsProps extends Omit<PatchCmsIntegrationSettingsProps, 'cmsIntegrationSettingsPayload'> {
    hubspotIntegrationSettingsPayload: Partial<HubspotIntegrationSettings>;
    fetchAndUpdateCmsIntegrationSettings: AuthContextInterface['fetchAndUpdateCmsIntegrationSettings'];
}
export const patchHubspotIntegrationSettings = async ({
    cmsIntegrationSettingsId,
    hubspotIntegrationSettingsPayload,
    narrationSettingsId,
    narrationSettingsPayload,
    fetchAndUpdateCmsIntegrationSettings
}: PatchHubspotIntegrationSettingsProps) => {
    try {
        // * ensure method is Hubspot
        const cmsIntegrationSettingsPayload: Partial<HubspotIntegrationSettings> = {
            ...hubspotIntegrationSettingsPayload,
            method: CmsIntegrationSettingsMethodNameEnum.Hubspot
        };
        const documentId = await patchCmsIntegrationSettings({
            cmsIntegrationSettingsId,
            cmsIntegrationSettingsPayload,
            narrationSettingsId,
            narrationSettingsPayload
        });

        // * refresh the cms integration settings
        fetchAndUpdateCmsIntegrationSettings();

        // * remove cookie after creating document
        deleteHubspotDataCookie();

        return documentId;
    } catch (err) {
        const { message, cause } = formatSentryError(
            'Error hitting next API cms-integration-settings/patch/',
            'patchHubspotIntegrationSettings',
            err
        );
        captureException(new Error(message, cause));
        throw err;
    }
};

export const getAllCmsIntegrationSettingsWithNarrationSettingsByProject = async (projectId: FirestoreDocumentIdType) => {
    try {
        const cmsIntegrations = await getAllCmsIntegrationsByProjectId(projectId);

        // * attach the narration settings to rssSettings to make it easier to manipulate the data
        for (let i = 0; i < cmsIntegrations.length; i++) {
            const narrationSettings = await getNarrationSettingsById(cmsIntegrations[i].narrationSettingsId);
            cmsIntegrations[i].fetchedNarrationSettings = narrationSettings;
        }
        return cmsIntegrations;
    } catch (err) {
        // ! Don't log error because it may just not exist
        return [];
    }
};

export const filterRssIntegrationSettingsFromCmsIntegrationSettings = (cmsIntegrations: CmsIntegrationSettings[]) => {
    return cmsIntegrations.filter((integration) => integration.method === CmsIntegrationSettingsMethodNameEnum.RSS) as RSSIntegrationSettings[];
};

export const filterHubspotIntegrationSettingsFromCmsIntegrationSettings = (cmsIntegrations: CmsIntegrationSettings[]) => {
    return cmsIntegrations.filter(
        (integration) => integration.method === CmsIntegrationSettingsMethodNameEnum.Hubspot
    ) as HubspotIntegrationSettings[];
};

export const filterUnifiedIntegrationSettingsFromCmsIntegrationSettings = (cmsIntegrations: CmsIntegrationSettings[]) => {
    return cmsIntegrations.filter(
        (integration) => integration.method === CmsIntegrationSettingsMethodNameEnum.Unified
    ) as UnifiedIntegrationSettingsInterface[];
};

export const deleteCmsIntegrationSettings = async (
    cmsIntegrationSettingsId: FirestoreDocumentIdType,
    narrationSettingsId?: FirestoreDocumentIdType
) => {
    try {
        // * delete narration settings that is already attached to cmsIntegrationSettings if narrationSettingsId is passed
        if (narrationSettingsId) {
            await deleteNarrationSettings(narrationSettingsId);
        }
        const response = await axios.delete(`/api/v2/cms-integration-settings/delete/${cmsIntegrationSettingsId}`);
        return response.data as FirestoreDocumentIdType;
    } catch (err) {
        const { message, cause } = formatSentryError('Error hitting next API cms-integration-settings/delete/', 'delelteRssIntegrationSettings', err);
        captureException(new Error(message, cause));
        throw err;
    }
};

export const getCmsIntegrationSettingsByHubspotId = async (hubspotData: CmsIntegrationSettingsHubspotOptions) => {
    try {
        const response = await axios.get(`${process.env.OPERATIONS_REST_API_URL}/api/v1/cms-integration-settings/hubspot/${hubspotData.hubId}`);
        const cmsIntegrationSettings = response.data;
        return cmsIntegrationSettings;
    } catch (error) {
        console.error('Error fetching cmsIntegrationSettings:', error);
        // todo throw error if it fails, but don't throw error if the doc doesn't exist
    }
};

export const createCmsIntegrationSettingsDocumentFromHubspotCookie = async (
    hubspotData: CmsIntegrationSettingsHubspotOptions,
    group: GroupInterface,
    project: ProjectInterface,
    narrationSettings: NarrationSettingsInterface | null,
    fetchAndUpdateCmsIntegrationSettings: AuthContextInterface['fetchAndUpdateCmsIntegrationSettings']
) => {
    try {
        const payload: Partial<HubspotIntegrationSettings> = {
            groupId: group.id,
            projectId: project.id,
            options: hubspotData,
            isEnabled: false // * start disabled until the user enables it with their voice selections
        };
        // * For now, just create a copy of the default narration settings. User can edit later
        const newNarrationSettingsPayload = { ...narrationSettings };
        // * set default voices if not set
        if (!newNarrationSettingsPayload.bodyVoice || newNarrationSettingsPayload.bodyVoice === '') {
            newNarrationSettingsPayload.bodyVoice = 'en-US-NancyNeural';
        }
        if (!newNarrationSettingsPayload.titleVoice || newNarrationSettingsPayload.titleVoice === '') {
            newNarrationSettingsPayload.titleVoice = 'en-US-NancyNeural';
        }
        const hubspotIntegrationSettingsId = await postHubspotIntegrationSettings(payload, newNarrationSettingsPayload);

        // * refresh the cms integration settings
        fetchAndUpdateCmsIntegrationSettings();

        // * remove cookie after creating document
        deleteHubspotDataCookie();

        return hubspotIntegrationSettingsId;
    } catch (error) {
        const { message, cause } = formatSentryError(
            'Error creating cms integration settings from Hubspot cookie',
            'createCmsIntegrationSettingsDocumentFromHubspotCookie',
            error
        );
        captureException(new Error(message, cause));
        throw error;
    }
};

export const deleteHubspotDataCookie = () => {
    document.cookie = `${HUBSPOT_COOKIE_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const checkIfHubspotDataCookieExists = () => {
    return document.cookie.includes(HUBSPOT_COOKIE_NAME);
};
