import {
    CmsIntegrationSettingsMethodNameEnum,
    CmsIntegrationSettings,
    HubspotIntegrationSettings,
    NarrationSettingsInterface,
    RSSIntegrationSettings,
    NarrationMethodEnum,
    LanguageDropedownValueType
} from '../../types/types';

export const DEFAULT_NARRATION_SETTINGS = {
    name: 'Default', // * should be Group if attached to a group doc, or Integration if attached to a CMSIntegrationSettings doc
    intro: null,
    introText: null,
    outro: null,
    outroText: null,
    wordList: null,
    bodyVoice: '',
    titleVoice: '',
    language: 'en' as LanguageDropedownValueType,
    breakTimes: {
        title: null,
        paragraph: null,
        quote: null,
        outro: null
    },
    readQuoteMarks: true,
    rewritePrompt: {
        baseNarrationMethod: NarrationMethodEnum.SingleNarrationRequest
    },
    version: 3
};

export const DEFAULT_CMS_INTEGRATION_SETTINGS = {
    isEnabled: true,
    name: 'Integration'
};

export const DEFAULT_RSS_INTEGRATION_SETTINGS: Partial<RSSIntegrationSettings> = {
    ...DEFAULT_CMS_INTEGRATION_SETTINGS,
    method: CmsIntegrationSettingsMethodNameEnum.RSS
};

export const DEFAULT_HUBSPOT_INTEGRATION_SETTINGS: Partial<HubspotIntegrationSettings> = {
    ...DEFAULT_CMS_INTEGRATION_SETTINGS,
    method: CmsIntegrationSettingsMethodNameEnum.Hubspot
};

export const DEFAULT_UNIFIED_INTEGRATION_SETTINGS: Partial<CmsIntegrationSettings> = {
    ...DEFAULT_CMS_INTEGRATION_SETTINGS,
    method: CmsIntegrationSettingsMethodNameEnum.Unified
};
